import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { CalendarIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { removeTimeZoneFromDate } from '../../utils/calculateTime';
import { mergeClasses } from '../../utils/helpers';

export default function DropdownMenu({
    dropdownOptions,
    label,
    selector,
    setSelector,
    arrowDirectionUp,
    setStep = 1,
    type = 'default',
    placeholder = 'Choose an Option',
    calender = false,
    updateDateRange,
    itemName = 'Hardwares ',
    classNames = '',
    onSelect = () => { },
    onReset = () => { }
}) {
    const [recentSelection, setRecentSelection] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
    const defaultButtonStyles = `inline-flex w-full max-w-[320px]  justify-between items-center text-sm text-gray-900 ${calender ? 'pl-4' : 'px-4'} py-2.5 cursor-pointer gap-6`
    const menuButtonClasses = mergeClasses(defaultButtonStyles, classNames)

    useEffect(() => {
        const savedDateRange = JSON.parse(localStorage.getItem('dateRange'));
        if (savedDateRange) {
            setDateRange(savedDateRange);
        }

    }, []);

    const resetSelections = () => {
        setRecentSelection('');
        setSelector('');
        setDateRange({ startDate: '', endDate: '' });
        localStorage.removeItem('dateRange');
    };


    useEffect(() => {
        if (typeof onReset === 'function') {
            onReset(resetSelections);
        }
    }, [onReset]);


    const allSelected = selector.length === dropdownOptions.length;

    const handleDateChange = useCallback((key, value) => {
        const utcValue = dayjs(value).utc().format();
        setDateRange(prev => ({
            ...prev,
            [key]: utcValue
        }));
    }, []);



    useEffect(() => {
        if (recentSelection === 'Custom Range') {
            updateDateRange(dateRange);
        }
    }, [recentSelection]);

    const handleDates = () => {
        (dateRange.startDate && dateRange.endDate) && setRecentSelection('Custom Range');
        setIsCalendarOpen(false);
        setSelector('')
        localStorage.setItem('dateRange', JSON.stringify(dateRange));
    };


    const handleDropdownClick = useCallback((e) => {
        e.preventDefault();
        setIsDropdownOpen(prev => !prev);
        setIsCalendarOpen(false);
    }, [isDropdownOpen]);

    // const resetDateRange = () => {
    //     setDateRange({ startDate: '', endDate: '' });
    // };

    const handleCalendarClick = useCallback((e) => {
        e.preventDefault();
        setIsCalendarOpen(prev => !prev);
        setIsDropdownOpen(false);
    }, [isCalendarOpen]);



    function handleClick(valName, e) {

        e.preventDefault();
        e.stopPropagation();
        setRecentSelection(valName);


        const selectedOption = dropdownOptions.find(option => option.name === valName);
        const selectedId = selectedOption?.id;

        if (type === 'checkbox') {
            if (valName === 'Select All') {
                if (allSelected) {
                    setSelector([]);
                    onSelect([]);
                } else {
                    const allIds = dropdownOptions.map(option => option.id);
                    setSelector(allIds);
                    onSelect(allIds);
                }
                setIsDropdownOpen(false);
            } else {
                const isSelected = selector.includes(selectedId);
                if (isSelected) {
                    const updatedSelector = selector.filter(id => id !== selectedId);
                    setSelector(updatedSelector);
                    onSelect(updatedSelector);
                } else {
                    const updatedSelector = [...selector, selectedId];
                    setSelector(updatedSelector);
                    onSelect(updatedSelector);
                }
            }
        } else {
            setSelector(valName);
            calender && updateDateRange({ startDate: '', endDate: '' });
        }
    }



    useEffect(() => {
        setRecentSelection(selector.length > 0 ? selector : (dateRange.startDate.length || dateRange.endDate.length) ? 'Custom Range' : placeholder);
    }, [selector, recentSelection]);

    function isSelected(valName) {
        return selector.includes(valName);
    }

    const CheckBoxTypeMenuItem = () => {
        if (type !== 'checkbox') return
        return <Menu.Item>
            {({ active }) => (
                <button
                    className='text-gray-900 stroke-gray-400 group flex w-full items-center rounded-md px-2 py-2 text-sm'
                    onClick={(e) => handleClick('Select All', e)}
                >
                    <input
                        type="checkbox"
                        className="mr-2"
                        checked={allSelected}
                        readOnly
                    />
                    Select All
                </button>
            )}
        </Menu.Item>

    }

    const SimpleMenuItems = () => {

        return (dropdownOptions.map((val) => (
            <Menu.Item key={val.name}>
                {({ active }) => (
                    <button
                        className={`${isSelected(val.name)
                            ? 'bg-blue-50 text-blue-500 hover:stroke-blue-500 hover:fill-blue-500'
                            : 'text-gray-900 stroke-gray-400'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={(e) => handleClick(val.name, e)}
                    >
                        {type === 'checkbox' && (
                            <input
                                type="checkbox"
                                className="mr-2"
                                checked={isSelected(val.id)}
                                readOnly
                            />
                        )}
                        {val.name}
                    </button>
                )}
            </Menu.Item >
        ))
        )
    }

    const RenderCalenderComponent = () => {
        if (!isCalendarOpen) return
        return (
            <div className="absolute z-50 w-[20rem] mt-2 bg-white shadow-lg rounded-md px-6 py-4 right-0">
                <div className='flex flex-col gap-3'>
                    <h1 className='font-medium text-md'>Select Custom Date Range</h1>
                    <p className="text-sm text-gray-700">All dates and time are presented in UTC. Usage data available for the last 18 months.</p>
                    <div className='gap-2 flex flex-col'>
                        <div className='flex flex-col gap-1'>
                            <label className='text-sm font-medium'>Start Date</label>
                            <input type="datetime-local" className='border border-gray-300 px-3 py-2 text-gray-500 rounded-lg' onChange={(e) => handleDateChange('startDate', e.target.value)} value={removeTimeZoneFromDate(dateRange.startDate)} />
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='text-sm font-medium'>End Date</label>
                            <input type="datetime-local" className='border border-gray-300 px-3 py-2 text-gray-500 rounded-lg' onChange={(e) => handleDateChange('endDate', e.target.value)} value={removeTimeZoneFromDate(dateRange.endDate)} />
                        </div>
                    </div>

                    <div className='bg-blue-700 text-center text-sm font-medium mt-4 py-2 px-3 rounded-lg mb-1 w-20 text-white cursor-pointer' onClick={handleDates}>Select</div>
                </div>
            </div>
        )
    }

    const CalenderButton = () => {
        if (!calender) return

        return <div onClick={handleCalendarClick} className='ml-2 rounded-r-md cursor-pointer px-2 py-2.5 bg-gray-100 hover:bg-gray-200'>
            <CalendarIcon className="h-5 w-5 text-gray-600 " />
        </div>
    }

    const RenderDropdownMenu = () => {

        return <Menu as="div" className="relative inline-block">
            <div
                className="flex w-full justify-between items-center rounded-md bg-white  text-sm text-gray-900  border border-gray-300"
            >
                <Menu.Button
                    className={menuButtonClasses}
                    onClick={handleDropdownClick}
                >
                    <div className="text-[14px] flex-grow-1 whitespace-nowrap">
                        <span>
                            {type === 'checkbox' && selector.length > 0
                                ? `${itemName} Selected`
                                : recentSelection
                                    ? recentSelection
                                    : selector.length > 0
                                        ? selector
                                        : placeholder
                            }
                        </span>
                    </div>
                    <div className="flex">
                        {arrowDirectionUp ? (
                            <ChevronUpIcon className="ml-4 -mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        ) : (
                            <ChevronDownIcon className="ml-4 -mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        )}
                    </div>
                </Menu.Button>

                <CalenderButton />
            </div>


            <Transition
                as={Fragment}
                show={isDropdownOpen}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={`absolute z-50 w-full min-w-[150px] divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-72 overflow-y-auto
            ${arrowDirectionUp ? 'bottom-12' : 'mt-2'}`}
                >
                    <div className="px-1 py-1">
                        <CheckBoxTypeMenuItem />
                        <SimpleMenuItems />
                    </div>
                </Menu.Items>
            </Transition>
        </Menu >

    }

    return (
        <div className="w-full flex">
            <div className="relative w-full inline-block text-left">
                <div>
                    <span className="flex justify-start items-center text-[14px] mb-2">
                        {label}
                    </span>

                    <div className="flex">
                        <RenderDropdownMenu />
                    </div>
                </div>

                {/* Calendar Dropdown*/}
                <RenderCalenderComponent />
            </div>
        </div>
    );
}