import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { Loader } from '../components/Loader/Loader';
import MarkerDetails from '../components/MapView/MarkerDetails';
import DropdownMenu from '../components/Menus/DropdownMenu';
import { ErrorAlert } from '../components/Popups/ErrorAlert';
import { SearchableDropdown } from '../components/SearchableDropdown/SearchableDropdown';
import { useUser } from '../hooks/useAuth';
import { useFetchHardwares } from '../hooks/useHardware';
import { useGetAllUniqueJobs } from '../hooks/useJobs';
import { FILTER_LIST } from '../utils/contants';
import marker from './../assets/marker.png';

const center = {
  lat: 42.06012414889974,
  lng: -91.63162586405998
};

const containerStyle = {
  height: '600px',
  borderRadius: '10px',
  boxShadow: '0px 0px 20px #8888'
};
const initialDateRange = { startDate: '', endDate: '' }

function MapView() {
  const [mapWidth, setMapWidth] = useState(() => (window.innerWidth < 769 ? `${window.innerWidth - 40}px` : '800px'));
  useEffect(() => {
    const handleResize = () => {
      setMapWidth(window.innerWidth < 769 ? `${window.innerWidth - 40}px` : '800px');
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  });
  const { data: hardwareData } = useFetchHardwares();
  const [step, setStep] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedHardwareId, setSelectedHardwareId] = useState(null);
  const { data: userData } = useUser();
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(4);
  const [propertyId, setPropertyId] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [dateRange, setDateRange] = useState(initialDateRange);

  const isFilterApplied = useMemo(() => {
    const isDateRangeSelected = Boolean(selectedFilter);
    const isHardwareIdSelected = Boolean(selectedHardwareId);
    return isDateRangeSelected || isHardwareIdSelected || (dateRange.startDate || dateRange.endDate)
  }, [selectedFilter, selectedHardwareId, dateRange]);

  const resetDropdownRef = useRef(null);
  const onLoad = useCallback(map => setMap(map), []);
  const onUnmount = useCallback(() => setMap(null), []);

  useEffect(() => {
    if (selectedOption) {
      const hId = hardwareData.find(hardware => hardware.hwid === selectedOption)?._id;
      setSelectedHardwareId(hId);
    }
  }, [selectedOption, hardwareData]);

  const { data, isError, error, isLoading } = useGetAllUniqueJobs({
    user: userData?.user?._id,
    technician: userData?.user?.role === 'technician' ? userData?.user?._id : null,
    hardwareId: selectedHardwareId,
    selectedFilter: selectedFilter,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate
  });

  useEffect(() => {
    if (!propertyId) {
      setSelectedAddress(null);
      return;
    }

    const selected = data?.find(address => address?._id === propertyId);
    if (selected) {
      setSelectedAddress(selected);
      setZoom(20);
      map?.panTo(selected.mapCenter);
    }

    if (step === 1) {
      setZoom(4);
      setSelectedAddress(undefined);
    }
  }, [propertyId, data, map, step]);


  const clearFilters = useCallback(() => {
    setSelectedFilter('');
    setSelectedOption('')
    setSelectedHardwareId('');
    localStorage.removeItem('dateRange')
    setDateRange(initialDateRange);
    if (resetDropdownRef.current) {
      resetDropdownRef.current();
    }
    setStep(1)
  }, []);

  const dropdownOptions = hardwareData?.map(hardware => hardware.hwid);

  const onMarkerClick = jobAddressId => {
    setStep(2);
    setPropertyId(jobAddressId);
  };

  const DisplayAddress = () => {
    if (!selectedAddress) return data.map(jobAddress => (
      <Marker
        key={jobAddress._id}
        position={jobAddress.mapCenter}
        icon={{
          url: { marker },
          scaledSize: new window.google.maps.Size(50, 50)
        }}
        onClick={() => onMarkerClick(jobAddress._id)}
      />
    ));

    return (
      <Marker
        key={selectedAddress._id}
        position={selectedAddress.mapCenter}
        icon={{
          url: { marker },
          scaledSize: new window.google.maps.Size(50, 50)
        }}
        onClick={() => onMarkerClick(selectedAddress._id)}
      />
    );
  };

  if (isLoading || data === undefined) return <Loader />;
  if (isError) return <ErrorAlert errorMessages={error.message} />;

  return isLoaded ? (
    <DashboardLayout>
      <div className='w-full block p-5'>
        <div className='flex flex-wrap justify-end px-0 w-full xl:pr-[10%]'>
          <div className='flex mx-3 items-center mb-3 justify-center'>
            {isFilterApplied && (
              <button
                onClick={clearFilters}
                className="border-red-500 text-sm text-red-500 flex p-2 cursor-pointer rounded-lg border border-dashed"
              >
                Clear Filters
              </button>
            )}
          </div>
          <div className='flex mx-3 py-1 h-full'>
            <SearchableDropdown
              options={dropdownOptions}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              placeholder='Search by Hardware'
            />
          </div>
          <div className='flex max-w-[240px] mb-5 ml-2'>
            <DropdownMenu
              label=''
              dropdownOptions={FILTER_LIST}
              selector={selectedFilter}
              setSelector={setSelectedFilter}
              arrowDirectionUp={false}
              setStep={setStep}
              calender={true}
              dateRange={dateRange}
              updateDateRange={setDateRange}
              dateRangeValue={dateRange}
              classNames='w-[190px]'
              onReset={(resetFunction) => {
                resetDropdownRef.current = resetFunction;
              }}
            />
          </div>
        </div>
        <div className='flex md:flex-row flex-col gap-x-4'>
          <GoogleMap
            mapContainerStyle={{ ...containerStyle, width: mapWidth }}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
            zoom={zoom}
            options={{ mapTypeId: 'satellite' }}
          >
            <DisplayAddress />
          </GoogleMap>
          <MarkerDetails step={step} setStep={setStep} job={data} propertyId={propertyId} setPropertyId={setPropertyId} />
        </div>
      </div>
    </DashboardLayout>
  ) : null;
}

export default React.memo(MapView);
