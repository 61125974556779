export const PAGE_LIMIT = 9

export const FILTER_LIST = [
  { name: 'Today' },
  { name: 'Yesterday' },
  { name: 'This Week' },
  { name: 'Previous Week' },
  { name: 'Last 7 Days' },
  { name: 'This Month' },
  { name: 'Previous Month' },
  { name: 'Last 30 Days' },
  { name: 'Last 60 Days' },
  { name: 'Last Year' },
];