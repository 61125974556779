import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from "react"
import { getOwnerUser, getTechnicianByUser } from "../../hooks/useAuth"
import { useGetJobById } from '../../hooks/useJobs'
import { formatDate } from '../../utils/formateDate'
import { Loader } from '../Loader/Loader'
import { ErrorAlert } from '../Popups/ErrorAlert'


export default function Timeline({ jobId, setStep }) {

    const { data, isLoading, isError, error } = useGetJobById(jobId)
    const [name, setName] = useState("")

    const getTechnicianName = async () => {
        if (data?.technician === null) {
            const { data: owner } = await getOwnerUser(data?.ownerUserId)
            return owner?.username
        } else {
            const { data: technician } = await getTechnicianByUser(data?.ownerUserId)
            return technician?.technicianId?.username
        }

    }

    useEffect(() => {
        getTechnicianName().then((response) => {
            setName(response)
        })
    }, [data]);

    const handleBack = () => {
        setStep(2)
    }
    if (isLoading) return <Loader />
    if (isError) return <ErrorAlert errorMessages={error.message} />
    return (
        <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3 h-[600px] mt-1">
            <div className="px-4 py-3 sm:rounded-lg sm:px-6">
                <div className=''>
                    <button onClick={handleBack} type="button" className=" text-blue-700 hover:bg-blue-100 bg-blue-50 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center">
                        <ArrowLeftIcon className='w-6 h-6' />
                    </button>
                </div>
                <h2 id="timeline-title" className="text-lg font-medium text-gray-900 mt-2">
                    {data?.address.address}
                </h2>
                <div className="mt-6 flow-root">
                    <ul>
                        <li>
                            <div className="relative pb-8">
                                <div className="relative flex space-x-3">
                                    <div>
                                        <p className="font-medium text-gray-900">
                                            Visit Date
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            {formatDate(data.startTime)}
                                            Time
                                        </p>
                                        <br />
                                        <p className="font-medium text-gray-900">
                                            Technician
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            {name}
                                        </p>
                                        <br />
                                        <div>
                                            <p className="font-medium text-gray-900">
                                                Total Gallons
                                            </p>
                                            {data.chemicals.map(chemical => (
                                                <p className="text-sm text-gray-500">
                                                    <span>{data?.meta?.totalSprayUsed.toFixed(2)}</span>
                                                </p>
                                            ))}
                                        </div>
                                        <br />
                                        <div>
                                            <p className="font-medium text-gray-900">
                                                Chemical Used
                                            </p>
                                            {data.chemicals.map((chemical, index) => (
                                                <p className="flex justify-between items-center text-sm text-gray-500" key={index}>
                                                    <span>{chemical.chemicalName}</span>
                                                    <span>{data?.meta?.chemicalsData?.find(data => data.chemicalName === chemical.chemicalName)?.amountUsed?.toFixed(2)} gm</span>
                                                </p>
                                            ))}
                                        </div>
                                        <br />
                                        {data?.hardwares.length ? <div>
                                            <p className="font-medium text-gray-900">
                                                Hardwares Used
                                            </p>
                                            {data.hardwares.map((singleHardware, index) => (
                                                <p className="text-sm text-gray-500" key={index}>
                                                    {singleHardware.name}
                                                </p>
                                            ))}
                                        </div> : null}
                                        <br />
                                        <p className="font-medium text-gray-900">
                                            Time on Property
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            {data.jobDuration}
                                        </p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}