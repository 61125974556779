import dayjs from "dayjs"
import React from "react"
import { ErrorAlert } from "../Popups/ErrorAlert"

export default function SearchTable({ isLoading, isError, error, data }) {
    if (isError) return <ErrorAlert errorMessages={error.message} />
    return (
        <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <hr className="border-gray-300" />
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="">
                                <tr>
                                    <th scope="col" className="py-2 pl-4 pr-3 text-left sm:pl-6 font-medium text-gray-500">
                                        Address
                                    </th>
                                    <th scope="col" className="py-2 px-3 text-left text-gray-500 font-medium">
                                        Chemicals
                                    </th>
                                    <th scope="col" className="py-2 px-3 text-left text-gray-500 font-medium">
                                        Status
                                    </th>
                                    <th scope="col" className="py-2 px-3 text-left text-gray-500 font-medium">
                                        Technician
                                    </th>
                                    <th scope="col" className="py-2 px-3 text-left text-gray-500 font-medium">
                                        Date
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y">
                                {data?.jobs.length > 0 ? data?.jobs?.map((job) => {

                                    return <tr key={job._id} className="hover:bg-blue-50">
                                        <td className="whitespace-nowrap py-3 pl-4 pr-3 sm:pl-6">
                                            <div className="flex items-center">
                                                <a className="hover:text-blue-500" href={`./job-details/${job?._id}`}><p className="text-sm">{job?.address?.address}</p></a>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-3">
                                            <div>
                                                {job.chemicals.map(chemical => (
                                                    <p key={chemical._id} className="text-sm">{chemical.chemicalName}</p>
                                                ))}
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-3">
                                            <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 
                                                    ${job.active === true ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'}`}
                                            >
                                                {job.active === true ? 'In-Progress' : 'Completed'}
                                            </span>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-3 text-sm">{(job?.user ? job?.user[0]?.username : job?.technician[0]?.username) ?? job?.user?.username}</td>
                                        <td className="whitespace-nowrap px-3 text-sm py-3">
                                            {dayjs(job.startTime).format('DD MMM YYYY')}
                                        </td>
                                    </tr>
                                }) :
                                    <tr className="justify-center">
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <h1 className="font-semibold py-6 text-gray-500">No Results Found</h1>
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                        <hr />
                    </div>
                </div>
            </div>
        </div>
    )
}
