import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate'
import DashboardLayout from '../components/DashboardLayout'
import { Loader } from '../components/Loader/Loader'
import DropdownMenu from '../components/Menus/DropdownMenu'
import SearchTable from '../components/Search/SearchTable'
import { SearchableDropdown } from '../components/SearchableDropdown/SearchableDropdown'
import { getUserTechnicians, useUser } from '../hooks/useAuth'
import { useFetchHardwares } from '../hooks/useHardware'
import { useSearchJobs } from '../hooks/useJobs'
import { FILTER_LIST, PAGE_LIMIT } from '../utils/contants'
const initialDateRange = { startDate: '', endDate: '' }

const SearchFilters = ({ isFilterApplied, clearFilters, input, resetDropdownRef, setInput, handleKeyPress, handleHardwareChange, hardwareDropdownOptions, selectedOption, selectedTOption, technicianDropdownOptions, handleTechnicianChange, selectedFilter, setSelectedFilter, dateRange, setDateRange }) => {
    return (
        <div className='flex flex-wrap py-2 items-center justify-end'>
            <div className='flex mx-2 items-center mt-1.5 justify-center'>
                {isFilterApplied && (
                    <button
                        onClick={clearFilters}
                        className="border-red-500 text-sm text-red-500 flex p-2 cursor-pointer rounded-lg border border-dashed"
                    >
                        Clear Filters
                    </button>
                )}
            </div>
            <div className=" flex max-w-[260px] mt-1 items-center mx-2 border h-fit border-gray-300 px-2 rounded-md focus-within:ring-1 focus-within:ring-blue-500 focus-within:border-blue-500">
                <MagnifyingGlassIcon className='w-6 h-6 text-gray-400' />
                <input
                    type="text"
                    className='py-2 px-2 focus-visible:outline-none w-full'
                    placeholder='search'
                    value={input}
                    onChange={(e) => { setInput(e.target.value) }}
                    onKeyDown={handleKeyPress}
                />
            </div>
            <div className='flex mx-2 h-full'>
                <SearchableDropdown
                    options={hardwareDropdownOptions}
                    selectedOption={selectedOption}
                    setSelectedOption={handleHardwareChange}
                    placeholder='Search by Hardware'

                />
            </div>
            <div className='flex mx-2 h-full'>
                <SearchableDropdown
                    options={technicianDropdownOptions}
                    selectedOption={selectedTOption}
                    setSelectedOption={handleTechnicianChange}
                    placeholder='Search by Technician'

                />
            </div>
            <div className='flex max-w-[240px]  ml-2 mb-1'>
                <DropdownMenu
                    label=''
                    dropdownOptions={FILTER_LIST}
                    selector={selectedFilter}
                    setSelector={setSelectedFilter}
                    arrowDirectionUp={false}
                    calender={true}
                    classNames='w-[200px]'
                    dateRange={dateRange}
                    updateDateRange={setDateRange}
                    onReset={(resetFunction) => {
                        resetDropdownRef.current = resetFunction;
                    }}
                />
            </div>
        </div>
    )

}
export default function Search() {
    const { data: hardwareData } = useFetchHardwares();
    const [searchQuery, setSearchQuery] = useState('')
    const [input, setInput] = useState('')
    const [pageNumber, setPageNumber] = useState(0)
    const [selectedFilter, setSelectedFilter] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedTOption, setSelectedTOption] = useState('');
    const [technicians, setTechnicians] = useState([]);
    const [selectedTechnicianId, setSelectedTechnicianId] = useState('');
    const [selectedHardwareId, setSelectedHardwareId] = useState('');
    const [dateRange, setDateRange] = useState(initialDateRange);
    const { data: userData } = useUser()
    const { data, isLoading, isError, error, refetch, isFetching } = useSearchJobs({
        page: pageNumber,
        search: searchQuery,
        completeUser: userData?.user,
        role: userData?.user?.role,
        hwid: selectedHardwareId,
        selectedFilter: selectedFilter,
        technicianId: selectedTechnicianId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate
    }, {
        enabled: Boolean(userData),
    });
    const resetDropdownRef = useRef(null);

    const isFilterApplied = useMemo(() => (
        selectedFilter || selectedHardwareId || selectedTechnicianId || searchQuery || (dateRange.startDate || dateRange.endDate)
    ), [selectedFilter, selectedHardwareId, dateRange, selectedTechnicianId, searchQuery]);


    useEffect(() => {
        getAllTechnicians();
    }, [])

    const getAllTechnicians = async () => {
        const { data } = await getUserTechnicians(userData?.user?._id);
        setTechnicians(data)
    }


    const pageCount = useMemo(() => {
        let count = data && data.jobCount / PAGE_LIMIT;
        return count < 1 ? 0 : Math.ceil(count);
    }, [data]);


    useEffect(() => {
        if (userData && pageNumber !== undefined) {
            refetch()
        }
    }, [pageNumber, searchQuery, userData]);


    const handleClickSearch = useCallback(() => {
        setSearchQuery(input);
    }, [input]);

    const handlePageClick = useCallback((event) => {
        setPageNumber(event.selected);
    }, []);


    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSearch()
        }
    }

    const clearFilters = useCallback(() => {

        setSelectedOption('')
        setSelectedHardwareId('');
        setSelectedTechnicianId('')
        setSelectedTOption('')
        setSearchQuery('')
        setDateRange(initialDateRange)
        if (resetDropdownRef.current) {
            resetDropdownRef.current();
        }
    }, []);


    const handleHardwareChange = (option) => {
        const hId = hardwareData.find(hardware => hardware.hwid === option)?._id;
        setSelectedHardwareId(hId);
        setSelectedOption(option);
    };

    const handleTechnicianChange = (option) => {
        const tId = technicians.find(technician => technician?.technicianId?.username === option)?._id;
        setSelectedTechnicianId(tId);
        setSelectedTOption(option);
    };

    const hardwareDropdownOptions = hardwareData?.map(hardware => hardware.hwid);
    const technicianDropdownOptions = technicians?.map(technician => technician?.technicianId?.username);

    return (
        <DashboardLayout>
            <section className='mt-10 px-10'>
                <div className="px-4 sm:px-6 lg:px-8 w-full ">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-3xl font-medium text-gray-900">Search</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the jobs in your account including their address, status, chemicals and technician.
                            </p>
                        </div>
                    </div>
                    <SearchFilters
                        clearFilters={clearFilters}
                        dateRange={dateRange}
                        handleHardwareChange={handleHardwareChange}
                        handleKeyPress={handleKeyPress}
                        handleTechnicianChange={handleTechnicianChange}
                        hardwareDropdownOptions={hardwareDropdownOptions}
                        isFilterApplied={isFilterApplied}
                        selectedFilter={selectedFilter}
                        selectedOption={selectedOption}
                        setDateRange={setDateRange}
                        setSelectedFilter={setSelectedFilter}
                        technicianDropdownOptions={technicianDropdownOptions}
                        setInput={setInput}
                        input={input}
                        selectedTOption={selectedTOption}
                        resetDropdownRef={resetDropdownRef}
                    />
                </div>
                <div className="px-4 sm:px-6 lg:px-8">
                    {isLoading || isFetching ? <Loader /> : <SearchTable isError={isError} error={error} data={data} />}
                    <div className='mt-3 float-right'>
                        <ReactPaginate
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </div>
            </section>
        </DashboardLayout >
    )
}
