import { useLoadScript } from '@react-google-maps/api'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DashboardLayout from '../components/DashboardLayout'
import DropdownMenu from '../components/Menus/DropdownMenu'
import GoogleAutocompleteInput from '../components/Start/GoogleAutocompleteInput'
import { setChemicals, setHardwares } from '../features/jobDetailsSlice'
import { setJobInProgress } from '../features/jobInProgressSlice'
import { useUser } from '../hooks/useAuth'
import { useFetchChemicals } from "../hooks/useChemicals"
import { useFetchHardwares } from '../hooks/useHardware'
import { useCreateJob, useSearchJobs } from '../hooks/useJobs'
import { useCreateProperty } from '../hooks/useProperty'


// keeping libraries array as a const outside component due to performace warning in console
const libraries = ['places']

export default function Start() {
    const dispatch = useDispatch()
    const nav = useNavigate()
    const [selectedChemicals, setSelectedChemicals] = useState([]);
    const [selectedHardwares, setSelectedHardwares] = useState([]);
    const jobDetails = useSelector(state => state.jobDetails)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries,
    })
    const { mutateAsync: createJob } = useCreateJob()
    const { mutateAsync: createProperty } = useCreateProperty()
    const { data: userData } = useUser()
    const { data: chemicals } = useFetchChemicals()
    const { data: hardwares } = useFetchHardwares()
    const { data, refetch: refetchCurrentJobs } = useSearchJobs(
        {
            page: 1,
            search: '',
            user: userData?.user?.role === "user" ? userData?.user?._id : userData?.user?.ownerUserId, technician: userData?.user?.role === "technician" ? userData?.user?._id : null,
            completeUser: userData?.user,
            role: userData?.user?.role,
            active: true
        }
    )
    const date = new Date()
    // useEffect(() => {
    //     if (userData) {
    //         dispatch(setDefaultHardware(userData.user.defaultHardware))
    //     }
    // }, [])

    useEffect(() => {
        refetchCurrentJobs()
        if (!data?.jobs?.length) return;
        const { jobs } = data
        const currentOpenJob = jobs[0]
        dispatch(setJobInProgress(currentOpenJob))
        nav('/job')
    }, [userData, data])

    const onClickStartJob = async () => {
        const property = await createProperty({ address: jobDetails.address, mapCenter: jobDetails.mapCenter })
        const res = await createJob({
            address: property._id,
            chemicals: jobDetails.chemicals,
            hardwares: jobDetails.hardwares,
            ownerUserId: userData?.user.role === "user" ? userData?.user?._id : userData?.user?.ownerUserId,
            startTime: date,
            endTime: null,
            technician: userData?.user.role === "technician" ? userData?.user?._id : null
        })
        dispatch(setJobInProgress(res))
        nav('/job')
    }

    const chemicalOptions = chemicals?.map(item => ({
        id: item._id,
        name: item.chemicalName,
    }));

    const hardwareOptions = hardwares?.map(item => ({
        id: item._id,
        name: item.hwid,
    }));

    const onSelectChemical = (chemical, isSelected) => {
        if (isSelected) {
            setSelectedChemicals(prev => [...prev, chemical.id]);
        } else {
            setSelectedChemicals(prev => prev.filter(id => id !== chemical.id));
        }
    };


    const onSelectHardware = (hardware, isSelected) => {
        if (isSelected) {
            setSelectedHardwares(prev => [...prev, hardware.id]);
        } else {
            setSelectedHardwares(prev => prev.filter(id => id !== hardware.id));
        }
    };

    useEffect(() => {
        dispatch(setChemicals(selectedChemicals));
    }, [selectedChemicals, dispatch]);

    useEffect(() => {
        dispatch(setHardwares(selectedHardwares));
    }, [selectedHardwares, dispatch]);


    const DropdownWithSelectionBox = ({
        dropdownOptions,
        selectedItems,
        setSelectedItems,
        onSelect,
        placeholder,
        title,
        classNames,
        dropdownPosition
    }) => {
        return (
            <div className={`w-full sm:w-1/2 flex flex-col ${dropdownPosition === 'right' ? 'items-end pr-0 sm:pr-36' : 'items-start pl-0 sm:pl-36'}`}>
                {/* Dropdown */}
                <div>
                    <DropdownMenu
                        dropdownOptions={dropdownOptions}
                        selector={selectedItems}
                        setSelector={setSelectedItems}
                        arrowDirectionUp={false}
                        type="checkbox"
                        placeholder={placeholder}
                        onSelect={onSelect}
                        classNames={classNames}
                    />

                </div>

                <div className="w-full sm:w-[80%] flex flex-col border rounded-t-xl py-4 mt-4 divide-y overflow-hidden">
                    <h1 className="pb-2 text-center text-gray-900 font-semibold text-lg">
                        {title}
                    </h1>
                    <div className="p-2 flex flex-col gap-2 overflow-y-auto max-h-[300px] h-[200px]">
                        {selectedItems.length > 0 ? (
                            selectedItems.map((item, index) => (
                                <div
                                    key={index}
                                    className="text-md text-blue-500 bg-gray-50 shadow-md border border-gray-300 rounded-md p-2"
                                >
                                    {dropdownOptions.find(option => option.id === item)?.name || item}
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-500 text-sm text-center">No items selected</p>
                        )}
                    </div>
                </div>
            </div>
        );
    };


    return (
        <DashboardLayout>
            <div className='mt-10'>
                {isLoaded && <GoogleAutocompleteInput />}
            </div>

            {jobDetails.step === 2 &&
                <>
                    {/* <div className='m-5'>
                        <ChooseList title='Chemicals' data={chemicals} isError={isChemicalsError} isLoading={isChemicalsLoading} error={chemicalError} />
                    </div>
                    <div className='m-5'>
                        <ChooseList title='Hardwares' data={hardwares} isError={isHardwareError} isLoading={isHardwareLoading} error={hardwareError} />
                    </div> */}
                    <h1 className='text-3xl text-center py-6 font-medium text-blue-600 pb-4'>Chemicals & Hardwares</h1>
                    <div className="w-full gap-6 sm:gap-0 flex flex-col md:flex-row  flex-wrap items-start p-5 h-full sm:h-3/5">

                        <DropdownWithSelectionBox
                            dropdownOptions={hardwareOptions}
                            selectedItems={selectedHardwares}
                            setSelectedItems={setSelectedHardwares}
                            onSelect={onSelectHardware}
                            placeholder="Choose Single or Multiple Hardwares"
                            title="Selected Hardwares"
                            classNames="w-full sm:w-[320px]"
                            dropdownPosition='left'
                        />
                        <DropdownWithSelectionBox
                            dropdownOptions={chemicalOptions}
                            selectedItems={selectedChemicals}
                            setSelectedItems={setSelectedChemicals}
                            onSelect={onSelectChemical}
                            placeholder="Choose Single or Multiple Chemicals"
                            title="Selected Chemicals"
                            classNames="w-[320px]"
                            dropdownPosition='right'
                        />
                    </div>
                    {jobDetails.chemicals.length > 0 && jobDetails.hardwares.length > 0 &&
                        <div className="flex w-full justify-center py-2">
                            <button
                                className="bg-blue-600 text-white px-5 py-3 text-xl text-center rounded-md w-60 hover:bg-blue-800 transition-all tracking-wider"
                                onClick={onClickStartJob}>
                                START JOB
                            </button>
                        </div>
                    }
                </>
            }
        </DashboardLayout>
    )
}
